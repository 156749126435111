import "./Swimlanes.css";
import { DateTime } from "luxon";

// import Canvas from "./Canvas";
import { useRef, useEffect } from "react";

const dt = DateTime.now();
const mondayArray = computeMondays();
const dayArray = computeDays()
const environmentsArray = [
  "PROD",
  "PROD-1",
  "PROD-2",
  "STAGE",
  "STAGE-1",
  "STAGE-2",
  "UAT",
  "UAT-1",
  "UAT-2",
  "DEV",
  "DEV-1",
  "DEV-2"
]
// console.log(dayArray)

function computeMondays() {
  var mondaysArray = [];
  for (var i = 0; i < 52; i++) {
    mondaysArray.push(dt.startOf("week").plus({ days: 7 * i }).toLocaleString());
  }
  return mondaysArray;
}

function computeDays() {
  var daysArray = [];
  for (var i = 0; i < 364; i++) {
    daysArray.push(dt.startOf("week").plus({ days: 1 * i }).toLocaleString(DateTime.DATE_FULL));
  }
  return daysArray;
}

let yPosition

function Swimlanes({calendarData}) {
  
  //DrawEvents is a right mess but I am kind of proud of it
  //It iterates over all the environmentObjects indexes created in App.js
  //Sections it by Environment (prod/dev/etc)
  //Parses out the start/end dates
  //Finds the matching table cells values that match the start/end dates
  //Gets the pixel values of those matching cell
  //Builds the canvas shapes from the pixel values

  const cellRef = useRef({})
  const toolRef = useRef({})
  const loading = Object.keys(calendarData).length === 0

  const DrawEvents = props => {

    const canvasRef = useRef(null)
    
    useEffect(() => { 
      const canvas = canvasRef.current
      const context = canvas.getContext('2d')
      // console.log(canvas)

      function iterateEnvironmentInCalendarData(calendarData, key){

        // if you change any of these, you also need to change them AllEnvironmentsCalendar.jsx on lines 82,83,84,85
        let backgroundColor = ""
        if (key === "PROD") {
          backgroundColor = "rgb(0, 83, 4)"
        } else if ( key === "STAGE") {
          backgroundColor = "rgb(0, 156, 8)"
        } else if ( key === "UAT") {
          backgroundColor = "rgb(0, 1, 80)"
        } else if ( key === "DEV") { 
          backgroundColor = "rgb(0, 110, 255)"
        }

        let envIndex = 0
        calendarData[key].forEach(event =>
          {
          // console.log(event) 
      
            let skip = false
            
            let startDate = event["Start Date"].toLocaleString()
            let endDate = event["End Date"].toLocaleString()    
            const weekStart = DateTime.now().startOf('week').toLocaleString(DateTime.DATE_FULL)
      
            let compareArray = []
            compareArray.push(startDate)
            compareArray.push(endDate)
            compareArray.push(weekStart)
            compareArray.sort((a, b) => Date.parse(a) - Date.parse(b))

            if (compareArray[0] === startDate && compareArray[2] === endDate) {
              startDate = compareArray[1]
            } else if ((compareArray[2] === weekStart && startDate !== endDate) || (compareArray[2] === weekStart && startDate === endDate) ) {
              skip = true
            }

            if (typeof(cellRef.current[key][startDate]?.offsetLeft) === "undefined" && skip === false) {
              console.log(`The event ${event["Event Type"]} has an invalid format: ${startDate}, it must be 'Month DD, YYY'.`)
              skip = true
            }
            
            if (skip === false) {
              const cellStartDatePixel = (cellRef.current[key][startDate].offsetLeft)
              const cellEndDatePixel = (cellRef.current[key][endDate].offsetLeft)
              let eventWidth = cellEndDatePixel - cellStartDatePixel

              //This copies the key of the main 'for' loop to specify which lane within each ENV lane the event should go to
              let toolTipKey = `${key}`
              if (envIndex >= 0 && envIndex % 4 === 0){ 
                //for the top position
                yPosition = cellRef.current[toolTipKey][startDate].offsetTop + 24
              } else if ((envIndex - 1) % 2 === 0){
                //for the middle position
                toolTipKey = `${key}-1`
                yPosition = cellRef.current[toolTipKey][startDate].offsetTop + 24
              } else {
                //for the bottom position
                toolTipKey = `${key}-2`
                yPosition = cellRef.current[toolTipKey][startDate].offsetTop + 24
              }

              // console.log(toolTipKey)
              // Draws the box for the events on the swimlane
              toolRef.current[toolTipKey][startDate].innerText = `${startDate}:\n ${event["Event Type"]}\n ${event.Description}`
              toolRef.current[toolTipKey][startDate].style.backgroundColor = backgroundColor
              toolRef.current[toolTipKey][startDate].style.color = "white"

              // Styles the box
              context.fillStyle = backgroundColor
              context.fillRect(cellStartDatePixel + 1 , yPosition, eventWidth + 16, 33)
              context.strokeStyle = "black"
              context.strokeRect(cellStartDatePixel, yPosition, eventWidth + 16, 33)
              context.fillStyle = "black"
              // console.log(cellStartDatePixel)
              // console.log(cellRef.current[toolTipKey][startDate].offsetTop)


              const startDayIndex = startDate.lastIndexOf(",")
              const startDayInt = parseInt(startDate.slice((startDayIndex-2), startDayIndex))
              const endDayIndex = endDate.lastIndexOf(",")
              const endDayInt = parseInt(endDate.slice((endDayIndex-2), endDayIndex))

              let eventDuration = endDayInt-startDayInt

              //Corrects the duration count for events that occur between two months.
              if(eventDuration < 0) {
                eventDuration = (startDayInt - (eventDuration * -1) + endDayInt) -1
              }
              
              //This make sure that, when hovered over, each cell in the event range has the same info for the tooltip that pops up
              while (eventDuration > 0) {
                const startDateObject = new Date(startDate)
                const toolTipDatesToChange = new Date(startDateObject.setDate(startDateObject.getDate() + (eventDuration)))
                let refDate = DateTime.fromFormat(toolTipDatesToChange.toLocaleDateString(), 'D').toLocaleString(DateTime.DATE_FULL)
      
                //Draws tooltip popup using toolRef for each date in the event
                toolRef.current[toolTipKey][refDate].innerText = `${refDate}:\n ${event["Event Type"]}\n ${event.Description}`
                toolRef.current[toolTipKey][refDate].style.backgroundColor = backgroundColor
                toolRef.current[toolTipKey][refDate].style.color = "white"
                eventDuration -= 1
              }

              envIndex += 1
            }
            return 0
          }) 
      
      }
      
      if (!loading) {
          
        iterateEnvironmentInCalendarData(calendarData, "PROD")
            
        iterateEnvironmentInCalendarData(calendarData, "STAGE")
            
        iterateEnvironmentInCalendarData(calendarData, "UAT")
            
        iterateEnvironmentInCalendarData(calendarData, "DEV")
           
        }
    }, [])
    return <canvas ref={canvasRef} {...props}/>
    }

  
  return (
    <>
     <div className="special">
          <div className="lanes">
          <span id="PROD-lane" className="rotate-text">PROD</span>
          <span id="STAGE-lane" className="rotate-text">STAGE</span>
          <span id="UAT-lane" className="rotate-text">UAT</span>
          <span id="DEV-lane" className="rotate-text">DEV</span>
      </div>
      <div>

      <DrawEvents height= "420px" width="6300px" className="canvas"/>
      <table >
        <thead>
          <tr>
            {mondayArray.map((header, headerIndex) => (
              <th key={`Swimlane-${headerIndex}`} id={`Swimlane-weeks`} >{header}</th>
              ))}
          </tr>
        </thead>
      </table>
      <table className="layout" >
        <thead>
          <tr>
            {dayArray.map((header, headerIndex) => (
              <th key={`Swimlane-${headerIndex}`} id={`Swimlane-days`}>{header}</th>
              ))}
          </tr>
        </thead>
          <tbody>
            {environmentsArray.map((row, rowIndex) => (
              <tr key={`${rowIndex}`}><td id={`${row}`}
              ref={ref=>{if (ref) cellRef.current[ref.id]= {}; if(ref){toolRef.current[ref.id]={}}}}
              >{row}</td>
                {dayArray.map((cell, cellIndex) => (
                  <td key={cellIndex} id="days" name={row} className="parentCell"
                  ref={ref=>{if (ref) cellRef.current[row][cell] = ref}}
                  >
                  <span className={`toolTip ${row} ${cell}`} 
                  ref={ref=>{if (ref) toolRef.current[row][cell] = ref}}
                  >{cell}</span>
                  </td>
                  ))}</tr>
                  ))}
          </tbody>
        </table>
      </div>
    </div>
    </>
  );
}
export default Swimlanes;
