import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react"


export function useFetch(method, endpoint, data) {

  const { instance, accounts } = useMsal()
  const [value, setValue] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function invokeFetch() {
      try {
        const tokenRequest = {account : accounts[0]}
        const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
        const result = await method(endpoint, tokenResponse.idToken, data)
        // console.log(result)
        const json = await result.json()
        setValue(json)
      } catch (error) {
        console.log(error)
        setError(error)
      } finally {
        setLoading(false)
      }
    }

    invokeFetch()
  }, [accounts, instance, method, endpoint, data])

  return {value, error, loading};
}