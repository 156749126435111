import "./Header.css"
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import {Link} from 'react-router-dom';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


function SpliceInitialsFromUserName (userName) {
  const firstname = userName.split(" ")[0]
  const lastname = userName.split(" ")[1]
  const initials = firstname.charAt(0) + lastname.charAt(0)
  return initials
}

function ProfileToolTip(user) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Name: {user.name} <br/>
      Email: {user.username}
    </Tooltip>
 );
 return renderTooltip
}
function ApplicationsHeader() {
  const account = sessionStorage.getItem("msal.account.keys").slice(2, -2)
  const user = JSON.parse(sessionStorage.getItem(account))
  const userName = user.name
  
  const initials = SpliceInitialsFromUserName(userName)
  return (
    <Navbar sticky="top" style={{padding:"0px"}}>
      <Container id="navbar" style={{paddingRight:"0px", paddingTop:"8px", paddingBottom:"8px"}}>
        <Navbar.Brand>
            <Link to="/" style={{fontSize:"1.5em"}}>
              Ferguson Applications Environment Overview 
            </Link>
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Link to="https://mydigitalspace.sharepoint.com/sites/CoreApplications" style={{fontSize:"1.5em"}}>Enterprise Solutions</Link>
      </Container>
      <Navbar.Text id="profile"></Navbar.Text>
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={ProfileToolTip(user)}
      >
        <Navbar.Text data-letters={initials} style={{marginRight:"1em"}}>
        </Navbar.Text>
      </OverlayTrigger>
    </Navbar>
  );
}

export default ApplicationsHeader;