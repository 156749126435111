// App.js
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header'
import Home from './pages/Home';
import Sections from './components/SectionsOverview/SectionsOverview';
import EnvironmentActivities from './components/EnvironmentActivites/EnvironmentActivities';
import AllEnvironmentsCalendar from './components/AllEnvironmentsCalendar/AllEnvironmentsCalendar';
import SectionWithTable from './shared/Table/SectionWithTable';
import { useFetch } from './hooks/useFetch';
import { get } from './lib/api';


const App = () => {

   const { value: foundationalEnvironments, loading: loadingFoundationalEnvironments } = useFetch(get, "foundationalenvironments")
   const { value: cloneAndMaintSchedule, loading: loadingCloneAndMaintSchedule } = useFetch(get, "clonemaintschedule")
   const { value: boundaryApps, loading: loadingBoundaryApps } = useFetch(get, "boundaryapps")
   const { value: allEnvCalendar, loading: loadingAllEnvCalendar } = useFetch(get, "allenvcalendar")
   const { value: swimlanes, loading: loadingSwimlanes } = useFetch(get, "swimlanes")
   
   const validVanityURLArray = [
      "",
      "https://oracle.ferguson.com",
      "https://stage.oracle.ferguson.com",
      "https://uat.oracle.ferguson.com",
      "https://dev.oracle.ferguson.com",
    ]

    foundationalEnvironments?.forEach(element => {
      if (!validVanityURLArray.includes(element["Vanity URL"])){
         element["Vanity URL"] = "Invalid URL"
      }
      
    });
 
   const containerArray = []
   containerArray['Foundational Environments'] = {value: foundationalEnvironments, loading: loadingFoundationalEnvironments}
   containerArray['Clone and Maint Schedule'] = {value: cloneAndMaintSchedule, loading: loadingCloneAndMaintSchedule}
   containerArray['Boundary Apps'] = {value: boundaryApps, loading: loadingBoundaryApps}
   containerArray['All Environments Calendar'] = {value: allEnvCalendar, loading: loadingAllEnvCalendar}
   containerArray['Swimlanes'] = {value: swimlanes, loading: loadingSwimlanes}

   const environmentsObjects = {} 

   function FilterProd (containerArray) {
      let prodFilteredArray = containerArray.filter((object) =>
      object["Application"] === "PROD"
      )
      return prodFilteredArray
   }

   function FilterStage(containerArray) {
      let stageFilteredArray = containerArray.filter((object) => object["Description"].includes("to STAGE") || object["Application"].includes("STAGE"))
      return stageFilteredArray
   }

   function FilterDev(containerArray) {
      let devFilteredArray = containerArray.filter((object) => object["Description"].includes("to DEV") || object["Application"].includes("DEV"))
      return devFilteredArray
   }

   function FilterUat(containerArray){
      let uatFilteredArray = containerArray.filter((object) => object["Description"].includes("to UAT") || object["Application"].includes("UAT"))
      return uatFilteredArray
   }

   if (!loadingCloneAndMaintSchedule && !loadingAllEnvCalendar && !loadingSwimlanes) {
      // sorts cloneAndMaintSchedule by Date for table view
      cloneAndMaintSchedule.sort((a, b) => Date.parse(a["Start Date"]) - Date.parse(b["Start Date"]))
      
      // sorts all events into Environments
      let cloneAndMaintProd = FilterProd(cloneAndMaintSchedule)
      let cloneAndMaintStage = FilterStage(cloneAndMaintSchedule)
      let cloneAndMaintDev = FilterDev(cloneAndMaintSchedule)
      let cloneAndMaintUat = FilterUat (cloneAndMaintSchedule)
      
      let allEnvCalendarProd = FilterProd(allEnvCalendar)
      let allEnvCalendarStage = FilterStage(allEnvCalendar)
      let allEnvCalendarDev = FilterDev(allEnvCalendar)
      let allEnvCalendarUat = FilterUat (allEnvCalendar)
      
      let swimlanesProd = FilterProd(swimlanes)
      let swimlanesStage = FilterStage(swimlanes)
      let swimlanesDev = FilterDev(swimlanes)
      let swimlanesUat = FilterUat (swimlanes)

      environmentsObjects.PROD = cloneAndMaintProd
      allEnvCalendarProd.forEach(item => {environmentsObjects.PROD.push(item)});
      swimlanesProd.forEach(item => {environmentsObjects.PROD.push(item)});

      environmentsObjects.STAGE = cloneAndMaintStage
      allEnvCalendarStage.forEach(item => {environmentsObjects.STAGE.push(item)});
      swimlanesStage.forEach(item => {environmentsObjects.STAGE.push(item)});
      
      environmentsObjects.UAT = cloneAndMaintUat
      allEnvCalendarUat.forEach(item => {environmentsObjects.UAT.push(item)});
      swimlanesUat.forEach(item => {environmentsObjects.UAT.push(item)});
      
      environmentsObjects.DEV = cloneAndMaintDev
      allEnvCalendarDev.forEach(item => {environmentsObjects.DEV.push(item)});
      swimlanesDev.forEach(item => {environmentsObjects.DEV.push(item)});
   }

   // console.log(containerArray)
   // console.log(environmentsObjects)

 return (
    <>
      <Header />
      <Sections />
       <Routes>
          <Route path="/" element={<Home container={containerArray} calendarData={environmentsObjects} />} />
          <Route path ="/Environment Activities" element={<EnvironmentActivities data={containerArray} calendarData={environmentsObjects} />}/>
          <Route path ="/All Environments Calendar" element={<AllEnvironmentsCalendar container={containerArray}/>}/>
          <Route path ="/Foundational Environments" element={<SectionWithTable container={containerArray['Foundational Environments']} data={containerArray} section="Foundational Environments"/> }/>
          <Route path ="/Clone and Maintenance Schedule" element={<SectionWithTable container={containerArray['Clone and Maint Schedule']} data={containerArray} section="Clone and Maintenance Schedule"/> }/>
          <Route path ="/Boundary Applications" element={<SectionWithTable container={containerArray['Boundary Apps']} data={containerArray} section="Boundary Applications"/> }/>
       </Routes>
    </>
 );
};

export default App;