import "./AllEnvironmentsAndCalendarsForm.css"
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { post } from '../../lib/api';
import { useMsal } from '@azure/msal-react';
import { useState } from 'react';
import { DateTime } from 'luxon';

// NEEDS ERROR HANDLING IN POST FUNCTIONS

export default function ScheduleCalendarForm(data) {
  
  // console.log(data)
  
  const boundaryApps = OnlyBoundaryAppsApplicationsKeyValue(data)
  const uniqueBoundaryAppsList = RemoveBoundaryAppsDuplicates(boundaryApps)
  const foundationalEnvs = data["data"]['Foundational Environments']["value"].map((env) => env["SDLC Name"])
  
  
  const [dropDownSelection, setDropDownSelections] = useState("0")
  
  const { instance, accounts } = useMsal()
  
  
  function OnlyBoundaryAppsApplicationsKeyValue (data) {
    const loadingBoundaryApps = data["data"]['Boundary Apps']["loading"]
    const boundaryAppsData = data["data"]['Boundary Apps']["value"]
    if(!loadingBoundaryApps){
      const boundaryApps = boundaryAppsData.map((app) => app)
      const boundaryApplicationKeyValues = loadingBoundaryApps ? [] : boundaryApps.map((app) => app["Application"])
      return boundaryApplicationKeyValues
    }
  }
  
  function RemoveBoundaryAppsDuplicates (boundaryApps) {
    const loadingBoundaryApps = data["data"]['Boundary Apps']["loading"]
    const uniqueBoundaryApps = []
    if(!loadingBoundaryApps){
      boundaryApps.forEach(element => uniqueBoundaryApps.push(element))
      const unique = boundaryApps.filter(function(element, index, self) {
        return index === self.indexOf(element)        
      })
      return unique.sort()
    }
  }
 
  function checkUndefined (e) {
    var application = ""
    if (e.target.blank) {
       application = e.target.blank.value
    } else if (e.target.BoundaryApps !== undefined) {
      application = e.target.BoundaryApps.value
    }else if (e.target.FoundationalApps !== undefined) {
      application = e.target.FoundationalApps.value
    }
    return application
  }

  function convertDate (date) {
    const convertedDate = DateTime.fromISO(date).toFormat("LLLL d, yyyy")
    return convertedDate
  }

  async function postCloneAndMaintIfChecked (e) {
    const cloneMaintSchedule = e.target.clonemaintschedule.checked
    const message = "Clone and Maintenance Schedule \n"
    const convertedStartDate = convertDate(e.target.StartDate.value)
    const convertedEndDate = convertDate(e.target.EndDate.value)

    if (cloneMaintSchedule) {
      const application = checkUndefined(e)
      e.preventDefault()
      const body = 
        {
          "Event Type": e.target.eventType.value,
          "Description": e.target.Description.value,
          "Start Date": convertedStartDate,
          "End Date": convertedEndDate,
          "SR Number": e.target.SRNumber.value,
          "Notes": e.target.Notes.value,
          "Application": application,
          "Instance": e.target.Instance.value
        }
      
      const tokenRequest = {account : accounts[0]}
      const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
      const response = post("clonemaintschedule", tokenResponse.idToken, JSON.stringify(body))
      console.log(response)

      return message
    }
    
  }

  async function postAllEnvCalendarIfChecked (e) {
    const allEnvCalendar = e.target.allenvcalendar.checked
    const message = "All Environments Calendar Only \n"

    if (allEnvCalendar) {
      const application = checkUndefined(e)
      const validStartDateFormat = convertDate(e.target.StartDate.value)
      const validEndDateFormat = convertDate(e.target.EndDate.value)
      e.preventDefault()
      const body = 
        {
          "Event Type": e.target.eventType.value,
          "Description": e.target.Description.value,
          "Start Date": validStartDateFormat,
          "End Date": validEndDateFormat,
          "SR Number": e.target.SRNumber.value,
          "Notes": e.target.Notes.value,
          "Application": application,
          "Instance": e.target.Instance.value
        }
      
      const tokenRequest = {account : accounts[0]}
      const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
      const response = post("allenvcalendar", tokenResponse.idToken, JSON.stringify(body))
      console.log(response)

      return message
    }
  }

  async function postEnvActivitiesIfChecked (e) {
    const swimlanes = e.target.swimlanes.checked
    const message = "Swimlanes Only\n"

    if (swimlanes) {
      const application = checkUndefined(e)
      const validStartDateFormat = convertDate(e.target.StartDate.value)
      const validEndDateFormat = convertDate(e.target.EndDate.value)
      e.preventDefault()
      const body = 
        {
          "Event Type": e.target.eventType.value,
          "Description": e.target.Description.value,
          "Start Date": validStartDateFormat,
          "End Date": validEndDateFormat,
          "SR Number": e.target.SRNumber.value,
          "Notes": e.target.Notes.value,
          "Application": application,
          "Instance": e.target.Instance.value
        }
      
      const tokenRequest = {account : accounts[0]}
      const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
      const response = post("swimlanes", tokenResponse.idToken, JSON.stringify(body))
      console.log(response)

      return message
    }
    
  }

  function validateDate (e) {
    let errorMessage = ""
    let isvalid = true
    const convertedStartDate = convertDate(e.target.StartDate.value)
    const convertedEndDate = convertDate(e.target.EndDate.value)
    const convertedStartDateYear = +convertedStartDate.split(",")[1]
    const convertedEndDateYear = +convertedEndDate.split(",")[1]
    const unixStartDate = Date.parse(convertedStartDate)
    const unixEndDate = Date.parse(convertedEndDate)
    const currentYear = DateTime.now().year
    const nextYear = DateTime.now().year + 1

    if (convertedStartDate === "Invalid DateTime" || convertedEndDate === "Invalid DateTime") {
      errorMessage = "Please correct:\n"
      isvalid = false
      if (convertedStartDate === "Invalid DateTime") {
        errorMessage = errorMessage + `Start Date: ${e.target.StartDate.value}\n`
      }
      if (convertedEndDate === "Invalid DateTime") {
        errorMessage = errorMessage + `End Date: ${e.target.EndDate.value}\n`
      }
    }

    if (unixStartDate > unixEndDate) {
      isvalid = false
      errorMessage = errorMessage + "Start Date must be before End Date\n"
    }

    if (convertedStartDateYear !== currentYear && convertedStartDateYear !== nextYear) {
      isvalid = false
      errorMessage = errorMessage + `Start Date must be in ${currentYear} or ${nextYear}\n`
    }

    if (convertedEndDateYear !== currentYear && convertedEndDateYear !== (nextYear)) {
      isvalid = false
      errorMessage = errorMessage + `End Date must be in ${currentYear} or ${nextYear}\n`
    }

    return {errorMessage, isvalid}
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const valid = validateDate(e).isvalid
    const errorMessage = validateDate(e).errorMessage


    const confirmArray = []

    if (valid === true) {
    confirmArray[0] = await postCloneAndMaintIfChecked(e) || ""
    confirmArray[1] = await postAllEnvCalendarIfChecked(e) || ""
    confirmArray[2] = await postEnvActivitiesIfChecked(e) || ""
    }


    const message = ((confirmArray.every(item => item === "") || confirmArray.length === 0) && valid === true) 
    ? "Nothing added, please choose where to add this item. " 
    : valid === false ? errorMessage
    : `Event added to:\n${confirmArray[0]}${confirmArray[1]}${confirmArray[2]}`

    alert(message)
  }
  
 
  
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className='mb-3'>
        <Form.Select
        className="form-select"
        onChange={(e) => setDropDownSelections(e.target.value)}
        >
          <option value="0">Select Application Type</option>
          <option>Oracle</option>
          <option>Boundary</option>
        </Form.Select>
      </Form.Group>

      <FloatingLabel controlId="Event Type" label="Event Type">
        <Form.Select
        className="form-select"
        name="eventType"
        required
        >
        <option></option>
        <option>Events</option>
        <option>Oracle Update</option>
        <option>Testing</option>
        <option>Refresh</option>
        <option>Code Freeze</option>
        <option>Accounting Event</option>
        </Form.Select>
      </FloatingLabel>
    
      <FloatingLabel controlId="Description" label="Description">
        <Form.Control
        placeholder="Description"
        name="Description"
        required
        />
      </FloatingLabel>

      <FloatingLabel controlId="Start Date" label="Start Date">
        <Form.Control
        type="date"
        placeholder="Start Date"
        name="StartDate"
        required
        />
      </FloatingLabel>

      <FloatingLabel controlId="End Date" label="End Date">
        <Form.Control
        type="date"
        placeholder="End Date"
        name="EndDate"
        required
        />
      </FloatingLabel>

      <FloatingLabel controlId="SR Number" label="SR Number">
        <Form.Control
        placeholder="SR Number"
        name="SRNumber"
        />
      </FloatingLabel>

      <FloatingLabel controlId="Additional Notes" label="Additional Notes">
        <Form.Control
        placeholder="Additional Notes"
        name="Notes"
        />
      </FloatingLabel>

      {dropDownSelection === "0" && (
        <FloatingLabel controlId="Boundary Applications" label="Applications">
          <Form.Select
          placeholder=""
          name="blank"
          >
          <option></option>
          </Form.Select>
        </FloatingLabel>
      )}
  
      {dropDownSelection === "Oracle" && (
        <FloatingLabel controlId="Foundational Applications" label="SDLC Name">
        <Form.Select
        placeholder="Instance"
        name="FoundationalApps"
        >
          <option></option>
          {foundationalEnvs && foundationalEnvs.map((app, appIndex) => (
            <option value={`${app}`} key={`${app} Index:${appIndex}`}>{app}</option> ))}
        </Form.Select>
      </FloatingLabel>
      )}

      {dropDownSelection === "Boundary" && (
        <FloatingLabel controlId="Boundary Applications" label="Boundary">
          <Form.Select
          placeholder="Boundary Applications"
          name="BoundaryApps"
          >
            {uniqueBoundaryAppsList && uniqueBoundaryAppsList.map((app, appIndex) => (
              <option value={`${app}`} key={`${app} Index:${appIndex}`}>{app}</option> ))}
          </Form.Select>
        </FloatingLabel>
      )}

      <FloatingLabel controlId="Instance" label="Instance">
        <Form.Control
        placeholder="Instance"
        name="Instance"
        />
      </FloatingLabel>
    
      <br/>
      <div id="env-checkboxes">
        <div>
          <Form.Check
          type="switch"
          id="1"
          label="Environment Activities Only"
          value="swimlanes"
          name="swimlanes"
          />
          <Form.Check
          type="switch"
          id="2"
          label="All Environments Calendar Only"
          value="allenvcalendar"
          name="allenvcalendar"
          />
        </div>
        <div id="word-wrap-clone-and-maint-checkbox">
          <Form.Check className='clone-and-maint-checkbox'
          type="switch"
          id="clone-and-maint-checkbox"
          label="Clone and Maintenance Schedule + Calendars"
          value="clonemaintschedule"
          name="clonemaintschedule"
          />
        </div>
      </div>
      <br/>
      <Button variant="primary" type="submit">
        Confirm
      </Button>
    </Form>
  )
}