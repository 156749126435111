import Swimlanes from "./Swimlanes/Swimlanes";
import "./EnvironmentActivities.css";
import { useLocation } from "react-router-dom";
import useHasAdmin from "../../hooks/hasAdmin";
import ModalForCosmosUpdate from "../../shared/ModalToUpdateCosmosDB";
import ModalToReplaceItemInCosmosDB from "../../shared/ModalToReplaceItemInCosmosDB";

function EnvironmentActivities({data, calendarData}) {
 const hasAdmin = useHasAdmin() 
 const location = useLocation()

  return (
    <>
    
      <div className="nav-bumper" id="environment-activities" />
      <div className="section-wrapper" id="swimlanes-section">
        <h1>&nbsp;Environment Activities</h1>
        {location.pathname ==="/" && hasAdmin &&
    <ModalForCosmosUpdate data={data}/>
    }
      {location.pathname !=="/" && hasAdmin && 
    <ModalForCosmosUpdate data={data}/>
    }
           {location.pathname ==="/" && hasAdmin &&
    <ModalToReplaceItemInCosmosDB data={data} section="Swimlanes" add="false"/>
    }
        {location.pathname !=="/" && hasAdmin &&
    <ModalToReplaceItemInCosmosDB data={data} section="Swimlanes" add="false"/>
    }
        <div className="scroll-table-x" style={{height:"500px"}}>
          <Swimlanes calendarData={calendarData} />
        </div>
      </div>
    </>
  );
}

export default EnvironmentActivities;
