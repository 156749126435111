import { useMsal } from '@azure/msal-react';
import { post } from '../../lib/api';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form'

export default function FoundationalEnvironmentsFormGroup() {

  
  const oICArray = [
    "",
    "BRIPRDOIC02",
    "BRIUATOIC11",
    "BRIUATOIC00",
    "BRIDEVOIC",
    "BRIDEVOIC5",
    "BRIUATOIC00",
    "BRIUATOIC01",
    "BRIUATOIC02",
    "BRIUATOIC03",
    "BRIUATOIC04",
    "BRIUATOIC05",
    "BRIUATOIC06",
    "BRIUATOIC07",
    "BRIUATOIC08",
    "BRIUATOIC09",
    "BRIUATOIC10",
    "BRIUATOIC11",
    "BRIUATOIC12",
    "BRIUATOIC13",
    "BRIUATOIC14",
    "BRIUATOIC15",
    "BRIPRDOIC",
    "BRIPRDOIC00",
    "BRIPRDOIC01",
    "BRIPRDOIC02",
    "BRIPRDOIC03",
    "BRIPRDOIC04",
    "BRIPRDOIC05",
    "BRIPRDOIC06",
    "BRIPRDOIC07",
    "BRIPRDOIC08",
    "BRIPRDOIC09",
    "BRIPRDOIC10",
    "BRIPRDOIC11",
    "BRIPRDOIC12",
    "BRIPRDOIC13",
    "BRIPRDOIC14",
    "BRIPRDOIC15"
  ]

  const validVanityURLArray = [
    "",
    "https://oracle.ferguson.com",
    "https://stage.oracle.ferguson.com",
    "https://uat.oracle.ferguson.com",
    "https://dev.oracle.ferguson.com",
  ]

  const { instance, accounts } = useMsal()

  const onSubmit = async e => {
    e.preventDefault()

    if (e.target.VanityURL.value === "" || !validVanityURLArray.includes(e.target.VanityURL.value)) {
      return alert("Please enter a valid Vanity URL")
    }
    
    const body = 
    {
      "SDLC Name": e.target.SDLCNameEnglish.value,
      "Technical Environment": e.target.TechnicalEnvironmentName.value,
      "Instance Name": e.target.InstanceName.value,
      "Environment ID": e.target.EnvironmentID.value,
      "Use": e.target.Use.value,
      "Performance Sizing": e.target.PerformanceSizing.value,
      "Vanity URL": e.target.VanityURL.value,
      "Last Refresh Date": e.target.LastRefreshDate.value,
      "OIC: BusOps": e.target.OICBusOps.value,
      "OIC: Finance": e.target.OICFinance.value,
      "EPBCS": e.target.Epbcs.value,
      "ARCS": e.target.Arcs.value,
      "EDMCS": e.target.Edmcs.value,
      "FCCS": e.target.Fccs.value,
      "Source URL": e.target.SourceURL.value,
    }
    console.log(JSON.stringify(body))
    
    const tokenRequest = {account : accounts[0]}
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
    const response = post("foundationalenvironments", tokenResponse.idToken, JSON.stringify(body))
    console.log(response)


    alert("Foundational Environment Added")
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <FloatingLabel controlId="SDLC Name - English Name" label="SDLC Name - English Name">
          <Form.Control
          placeholder="SDLC Name - English Name"
          name="SDLCNameEnglish"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Technical Environment Name" label="Technical Environment Name">
          <Form.Control
          placeholder="Technical Environment Name"
          name="TechnicalEnvironmentName"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Instance Name" label="Instance Name">
          <Form.Control
          placeholder="Instance Name"
          name="InstanceName"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Environment ID" label="Environment ID">
          <Form.Control
          placeholder="Environment ID"
          name="EnvironmentID"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Use" label="Use">
          <Form.Control
          placeholder="Use"
          name="Use"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Performance Sizing" label="Performance Sizing">
          <Form.Control
          placeholder="Performance Sizing"
          name="PerformanceSizing"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="Vanity URL" label="Vanity URL">
          <Form.Select
          className="form-select"
          name="VanityURL"
          required>
          {validVanityURLArray.map((validURL, validURLIndex) => (
            <option value={`${validURL}`} key={`${validURLIndex}`}>{validURL}</option> ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="Last Refresh Date" label="Last Refresh Date">
          <Form.Control
          placeholder="Last Refresh Date"
          name="LastRefreshDate"
          required
          />
        </FloatingLabel>
        <FloatingLabel controlId="OIC: BusOps" label="OIC: BusOps">
        <Form.Select
          className="form-select"
          name="OICBusOps">
            {oICArray.map((instance, instanceIndex) => (
            <option value={`${instance}`} key={`${instanceIndex}`}>{instance}</option> ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="OIC: Finance" label="OIC: Finance">
          <Form.Select
          className="form-select"
          name="OICFinance">
            {oICArray.map((instance, instanceIndex) => (
              <option value={`${instance}`} key={`${instanceIndex}`}>{instance}</option> ))}
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="EPBCS" label="EPBCS">
          <Form.Select
          className="form-select"
          name="Epbcs"
          >
          <option></option>
          <option>epm</option>
          <option>epm-test</option>
          <option>epm3</option>
          <option>epm3-test</option>
          <option>epm4</option>
          <option>epm4-test</option>
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="ARCS" label="ARCS">
          <Form.Select
          className="form-select"
          name="Arcs"
          >
          <option></option>
          <option>arcs</option>
          <option>arcs-test</option>
          <option>arcs3</option>
          <option>arcs3-test</option>
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="EDMCS" label="EDMCS">
          <Form.Select
          className="form-select"
          name="Edmcs"
          >
          <option></option>
          <option>edmcs</option>
          <option>edmcs-test</option>
          <option>edmcs3</option>
          <option>edmcs3-test</option>
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="FCCS" label="FCCS">
          <Form.Select
          className="form-select"
          name="Fccs"
          >
          <option></option>
          <option>epm5</option>
          <option>epm5-test</option>
          <option>epm7</option>
          <option>epm7-test</option>
          <option>epm9</option>
          <option>epm9-test</option>
          </Form.Select>
        </FloatingLabel>
        <FloatingLabel controlId="Source URL" label="Source URL">
          <Form.Control
          name="SourceURL"
          placeholder="Source URL"
          required
          />
        </FloatingLabel>
        <br></br>
        <Button variant="primary" type="submit">
            Confirm
          </Button>
      </Form>  
    </>
  )
}