const API = process.env.REACT_APP_API;
const HEADERS = {
  "Accept": "application/json",
  "Content-Type": "application/json",
};


export async function get(endpoint, token) {
  const response = await fetch(`${API}/${endpoint}`, {
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    },
  });
  return response;
}

export async function post(endpoint, token, data) {
  if (!data || data === "{}") throw new Error()
  const response = await fetch(`${API}/${endpoint}`, {
    method: "POST",
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }, body: data
  })
  return response
}

export async function put(endpoint, token, body) {
  if (!body || body === "{}") throw new Error()
  const response = await fetch(`${API}/${endpoint}`, {
    method: "PUT",
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }, body: body
  })
  return response
}

export async function remove(endpoint, token, body) {
  if (!body || body === "{}") throw new Error()
  const response = await fetch(`${API}/${endpoint}`, {
    method: "DELETE",
    headers: {
      ...HEADERS,
      "Authorization": `Bearer ${token}`
    }, body: body
  })
  return response
}