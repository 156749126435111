import Button from "react-bootstrap/esm/Button"
import Form from "react-bootstrap/Form"
import { useEffect, useState } from "react"
import { put, remove } from "../lib/api"
import { useMsal } from "@azure/msal-react"
import { DateTime } from "luxon"

export default function Row ({row, headers, section, edit}) {
  // console.log(row)

  const { instance, accounts } = useMsal()

  const [rowValues, setRowValues] = useState(row)
  const [isEditing, setIsEditing] = useState(false)

  useEffect (()=> {
    setRowValues(row)
  },[row])

  function convertDate (date) {
    console.log(date)
    const convertedDate = DateTime.fromISO(date).toFormat("LLLL d, yyyy")
    if (convertedDate === "Invalid DateTime") {
      return date
    }
    return convertedDate
  }

  function convertDateWhenEditing (date) {
    let jsDate = new Date(date)
    let dateMonth = (jsDate.getUTCMonth() + 1).toString()
    let dateDay = jsDate.getUTCDate().toString()
    let dateYear = jsDate.getUTCFullYear().toString()
    let editingDate = `${dateYear}-${dateMonth.padStart(2, "0")}-${dateDay.padStart(2, "0")}`

    return editingDate
  }

  function validateRowComponentDate (event) {
    console.log(event)
    let errorMessage = ""
    let isvalid = true
    const convertedStartDate = convertDate(event["Start Date"])
    const convertedEndDate = convertDate(event["End Date"])
    const convertedStartDateYear = +convertedStartDate.split(",")[1]
    const convertedEndDateYear = +convertedEndDate.split(",")[1]
    const unixStartDate = Date.parse(convertedStartDate)
    const unixEndDate = Date.parse(convertedEndDate)
    const currentYear = DateTime.now().year
    const nextYear = DateTime.now().year + 1

    if (convertedStartDate === "Invalid DateTime" || convertedEndDate === "Invalid DateTime") {
      isvalid = false
      errorMessage = "Please correct:\n"

      if (convertedStartDate === "Invalid DateTime") {
        errorMessage = errorMessage + `Start Date: ${event["Start Date"]}\n`
      }
      if (convertedEndDate === "Invalid DateTime") {
        errorMessage = errorMessage + `End Date: ${event["End Date"]}\n`
      }
    }

    if (unixStartDate > unixEndDate) {
      isvalid = false
      errorMessage = errorMessage + "Start Date must be before End Date\n"
    }

    if (convertedStartDateYear !== currentYear && convertedStartDateYear !== nextYear) {
      isvalid = false
      errorMessage = errorMessage + `Start Date must be in ${currentYear} or ${nextYear}\n`
    }

    if (convertedEndDateYear !== currentYear && convertedEndDateYear !== (nextYear)) {
      isvalid = false
      errorMessage = errorMessage + `End Date must be in ${currentYear} or ${nextYear}\n`
    }

    return {errorMessage, isvalid}
  }

  const validVanityURLArray = [
    " ",
    "https://oracle.ferguson.com",
    "https://stage.oracle.ferguson.com",
    "https://uat.oracle.ferguson.com",
    "https://dev.oracle.ferguson.com",
  ]

  const turnIntoInputs = () => setIsEditing(!isEditing)
  const putToCosmos = async () => {
    const body = rowValues
    const id = rowValues.id
    console.log(body)
    console.log(body)
    
    if (rowValues["Start Date"] && rowValues["End Date"]) { 

      validateRowComponentDate(rowValues)
      if (validateRowComponentDate(rowValues).isvalid === false) {
        alert(validateRowComponentDate(rowValues).errorMessage)
        return
      }
    }

    if (rowValues["Vanity URL"] && (rowValues["Vanity URL"] === " " || !validVanityURLArray.includes(rowValues["Vanity URL"]))) {
      return alert("Please enter a valid Vanity URL")
    }

    rowValues["Start Date"] = convertDate(rowValues["Start Date"])
    rowValues["End Date"] = convertDate(rowValues["End Date"])
    
    const tokenRequest = {account : accounts[0]}
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
    const response = await put(`${section}/${id}`, tokenResponse.idToken, JSON.stringify(body))
    console.log(JSON.stringify(body))
    setIsEditing(false)
    // console.log(body)
    return response
}

const deleteFromCosmos = async () => {
  const body = rowValues
  const id = rowValues.id
  
  const tokenRequest = {account : accounts[0]}
  const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
  const response = await remove(`${section}/${id}`, tokenResponse.idToken, JSON.stringify(body))
  console.log(JSON.stringify(body))
  setIsEditing(false)
  // console.log(body)
  return response
}

  return (  
    <tr>{ edit === true ? 
      <td id="buttons">
        <Button onClick={turnIntoInputs}>EDIT</Button>
        |
        <Button onClick={putToCosmos}>SAVE</Button>
        |
        <Button onClick={deleteFromCosmos}>REMOVE</Button>
      </td>
      : <td style={{display:"hidden"}}></td>
      }
      {headers.map((cell, cellIndex) => {
        return isEditing ? 
        <td key={cellIndex}>
          {cell === "Start Date" || cell === "End Date" ? 
          <Form.Control type="date"
            name="StartDate"
            defaultValue={convertDateWhenEditing(rowValues[cell])}
            onChange = {e => setRowValues({...rowValues, [cell]:convertDateWhenEditing(e.target.value)})}
            required
          />
          : cell === "Vanity URL" ?          
            <Form.Select
            className="form-select"
            name="VanityURL"
            defaultValue={rowValues[cell]}
            required
            onChange={e => setRowValues({...rowValues, [cell]:e.target.value})}>
              {validVanityURLArray.map((validURL, validURLIndex) => (
                <option value={`${validURL}`} key={`${validURLIndex}`}>{validURL}</option> ))}
              </Form.Select>
          : <input type="text" value={rowValues[cell]} onChange={e => setRowValues({...rowValues, [cell]:e.target.value})}></input>}
        </td>
        :<td key={cellIndex} style={row.Application === "" ? {backgroundColor: "lightgrey"} : {} }>
          {cell === "Vanity URL" && validVanityURLArray.includes(rowValues[cell]) ? 
            <a href={rowValues[cell]}>{rowValues[cell]}</a> 
          : rowValues[cell]}         
        </td>
      })}
    </tr>
)}