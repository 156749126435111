import EnvironmentActivities from '../components/EnvironmentActivites/EnvironmentActivities';
import AllEnvironmentsCalendar from '../components/AllEnvironmentsCalendar/AllEnvironmentsCalendar';
import SectionWithTable from '../shared/Table/SectionWithTable';

export default function Home({container, calendarData}) {
  // console.log(container)
  return (
    <>
    <div id="1">
    <EnvironmentActivities data={container} calendarData={calendarData}/>
    <SectionWithTable container={container['Foundational Environments']} data={container} section="Foundational Environments"/> 
    <SectionWithTable container={container['Clone and Maint Schedule']} data={container} section="Clone And Maintenance Schedule"/> 
    <AllEnvironmentsCalendar container={container}/>
    <SectionWithTable container={container['Boundary Apps']} data={container} section="Boundary Applications"/> 
    </div>
    </>
  );
}