import Modal from "react-bootstrap/Modal"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import FoundationalEnvironmentsFormGroup from "./FormGroups/FoundationalEnvironmentsForm";
import BoundaryAppsForm from "./FormGroups/BoundaryAppsForm";
import ScheduleCalendarForm from "./FormGroups/AllEnvironmentsAndCalendarsForm";


export default function ModalForCosmosUpdate (data) {

  // console.log(data)

  const [show, setShow] = useState(false)
  const [dropDownSelection, setDropDownSelections] = useState("0")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dataPass = data["data"]

  return (
    <>
      <Button variant="primary" style={{marginLeft:"5px", marginBottom:"5px"}} onClick={handleShow}>
        Add
      </Button>
      <Modal
      backdrop="static"
      keyboard="false"
      show={show}
      onHide={() => {
        handleClose();
        setDropDownSelections("0")
      }}
      dialogClassName="modal-width"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <Form.Group className="mb-3" controlId="SectionSelection">
        <Form.Select
          className="form-select"
          onChange={(e) => setDropDownSelections(e.target.value)}
        >
          <option value="0">Select a Section to Add to</option>
          <option value="1">Foundational Environments</option>
          <option value="2">Schedule / Calendar</option>
          <option value="3">Boundary App</option>
        </Form.Select>
      </Form.Group>
        {dropDownSelection === "1" && (
        <FoundationalEnvironmentsFormGroup/>
      )}
      {dropDownSelection === "2" && (
        <ScheduleCalendarForm data={dataPass} />
      )}
      {dropDownSelection === "3" && (
        <BoundaryAppsForm data={dataPass}/>
      )}
        <Button variant="secondary" onClick={() => {
          handleClose();
          setDropDownSelections("0")
        }}>
          Cancel
        </Button>
        </Modal.Body>
        
      </Modal>
    </>
  )
}