import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication, InteractionType } from "@azure/msal-browser"
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react"
import { msalConfig } from "./lib/authentication"
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import App from "./App"

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <MsalProvider instance={new PublicClientApplication(msalConfig)}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();