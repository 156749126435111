import "./SectionWithTable.css"
import { useState } from "react";
import { useLocation } from 'react-router-dom';
import useHasAdmin from "../../hooks/hasAdmin";
import Button from "react-bootstrap/esm/Button";
import ModalToUpdateCosmosDB from '../ModalToUpdateCosmosDB';
import RowComponent from "../../components/RowComponent.jsx"


export default function SectionWithTable({container, data, section, addButton}) {
  
  const hasAdmin = useHasAdmin()
  // console.log([container, data, section])
  // console.log(container)
  // console.log(data)

  // used so the route is known to conditionally render; lines 40-44
  const location = useLocation()

  let items = container["value"]
  const loading = container["loading"]
  // console.log(loading)
  // console.log(items)

  // Filters out items that are before yesterdays date to be inclusive
  // Because all dates parsed from the DB would be milliseconds for 12am of that day
  // comparing them to Date.now() would exclude "End Date"s that are today
  // because it would be milliseconds for the moment the page is loaded
  if (section === "Clone And Maintenance Schedule" && !loading) {
    let today = Date.now()
    let yesterday = today - 86400000
    items = items.filter((object) => Date.parse(object["End Date"]) >= yesterday)
  }

  const [edit, setEdit] = useState(false)
  const handleShow = () => setEdit(!edit);

  const rowsArray = loading ? [] : items

  // Returns the keys for 0th index of items after
  // Checking for not loading, has data, and it does not equal null
  const tableHeadersArray = (!loading && items.length > 0 && items[0] !== null) ? Object.keys(items[0]) : [];
  // finds the id key in array, which is the start of the meta tags in cosmosDB
  const idIndex = tableHeadersArray.lastIndexOf("id")
  // slices out everything before cosmosDB meta tags so the meta tags don't have headers
  // and won't populate those rows in the table
  const tableHeaders = tableHeadersArray.slice(0,idIndex);
  
  return  (
    <>
    <div className="nav-bumper" id={section}/>
    <div className="section-wrapper" >
      <h1>&nbsp;{section}</h1>
      {location.pathname ==="/" && addButton !== "false" && hasAdmin &&
    <ModalToUpdateCosmosDB data={data}/>
    }
      {location.pathname !=="/" && addButton !== "false" && hasAdmin &&
    <ModalToUpdateCosmosDB data={data}/>
    } 
      {hasAdmin && 
    <Button variant="primary" style={{marginLeft:"5px", marginBottom:"5px"}} onClick={handleShow}>
      Edit
    </Button>
    }
 
      <div className="scroll-table-x">
        <table>
          <thead>
            <tr>
              <th id="buttons"></th>
            {tableHeaders && tableHeaders.map((header, headerIndex) => (
              <th key={`${headerIndex}`}>{header}</th>
              ))}
            </tr>
            </thead>
            <tbody>
              {rowsArray && rowsArray.map((row) => (
              <RowComponent row={row} key={row.id} headers={tableHeaders} section={section} edit={edit}/>
              ))}
            </tbody>
        </table>
      </div>
    </div>
    </>
  );
}