import { useMemo } from "react";
import { useMsal } from "@azure/msal-react";

export default function useHasAdmin() {

  const { accounts } = useMsal()
  const { roles } = accounts[0].idTokenClaims
  const hasPermission = useMemo(() => roles && roles.some(role => {
    return role.startsWith("EnvOverview.") && role.endsWith(".Administrator")
  }), [roles])

  return hasPermission
}

