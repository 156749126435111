import "./SectionsOverview.css";
import CardGroup from "react-bootstrap/CardGroup";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import homeIcon from "../../assets/home-icon-24.svg"
import fergLogo from "../../assets/fergusonLogoWhite.png"

import { Link } from "react-router-dom";

function SectionsOverview() {
  const sectionsArray = [
    {
      // These are just in case there is ever a need to target any of these individually at these levels 
      id: 1,
      key: "section-acitivities", 
      titleID: "environment-activities-title",
      colID: "environment-activities-col",
      cardID: "environment-activities-card",
      name: "Environment Activities",
    },
    {
      id: 2,
      key: "section-foundational-environments",
      titleID: "foundational-environments-title",
      colID: "foundational-environments-col",
      cardID: "foundational-environments-card",
      name: "Foundational Environments",
    },
    {
      id: 3,
      key: "section-cloning-and-maintenance-schedule",
      titleID: "clone-and-maintenance-schedule-title",
      colID: "clone-and-maintenance-schedule-col",
      cardID: "clone-and-maintenance-schedule-card",
      name: "Clone and Maintenance Schedule",
    },
    {
      id: 4,
      key: "section-all-environments-calendar",
      titleID: "all-environments-calendar-title",
      colID: "all-environments-calendar-col",
      cardID: "all-environments-calendar-card",
      name: "All Environments Calendar",
    },
    {
      id: 5,
      key: "section-boundary-applications",
      titleID: "boundary-applications-title",
      colID: "boundary-applications-col",
      cardID: "boundary-applications-card",
      name: "Boundary Applications",
    },
  ];

  return (
    <div id="card-wrapper" style={{position: "sticky", top:"72px", zIndex:"1020"}}>
      <CardGroup >
        <Card className="sections-row non-bg-card" style={{padding: "0px", borderLeft: "0px"}}>
          <Link to="/" style={{color: "white"}}>    
            <Card.Body style={{backgroundColor: "rgb(0, 68, 106)" }}>
              <Card.Img style={{padding: "5px"}} src={homeIcon}/>
            </Card.Body>
          </Link>
        </Card>
        {sectionsArray.map((section, _) => (
          <Card key={section.key} className="sections-row non-bg-card" id={section.cardID}>
              <Link to={section.name} style={{color: "white"}}>
                <Card.Body style={{backgroundColor: "rgb(0, 68, 106)" }}>
                  <Card.Text>
                    {section.name}
                  </Card.Text>
                </Card.Body>
              </Link>
            </Card>
        ))}
        <Card style={{padding: "0px"}}>    
            <Card.Body id="sections-overview-bg">
              <Image src={fergLogo} style={{ maxHeight: "7.455vh"}}/>
            </Card.Body>
        </Card>
        <Card className="non-bg-card"style={{padding: "0", borderRight: "0px"}}>
          <Link to="https://mydigitalspace.sharepoint.com/sites/maps/" style={{color: "white"}}>    
            <Card.Body style={{backgroundColor: "rgb(0, 68, 106)" }}>
              <Card.Text>
                Application Landscape Map
              </Card.Text>
            </Card.Body>
          </Link>
        </Card>
      </CardGroup>
    </div>
  );
}

export default SectionsOverview;
