import "./AllEnvironmentsCalendar.css";
import React from "react";
import ModalForCosmosUpdate from "../../shared/ModalToUpdateCosmosDB";
import ModalToReplaceItemInCosmosDB from "../../shared/ModalToReplaceItemInCosmosDB";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, luxonLocalizer } from "react-big-calendar";
import { DateTime } from 'luxon';
import { useLocation } from "react-router-dom";
import useHasAdmin from "../../hooks/hasAdmin";



const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });


const AllEnvironmentsCalendarDisplay = ({container}) => {

  // console.log(container)

  const loadingCloneAndMaint = container["Clone and Maint Schedule"]["loading"]
  const cloneAndMaint = loadingCloneAndMaint ? [] : container["Clone and Maint Schedule"]["value"]
  const loadingAllEnvironmentsCalendar = container["All Environments Calendar"]["loading"]
  const allEnvironmentsCalendar = loadingAllEnvironmentsCalendar? [] : container["All Environments Calendar"]["value"]
  
  const views = {
    month: true
  }
  const events = []
  
  for (let value of Object.values(cloneAndMaint)) {
    // console.log(value)

    const startDate = new Date(value['Start Date'])
    const endDate = new Date(value['End Date'])
    const endDateInclusive = new Date(endDate.setDate(endDate.getDate() + 1))
    let title = value['Description']
    let application = value['Application']
    if (!value['End Date']) {
      value['End Date'] = value['Start Date']
    }

    var cloneAndMaintEvent = {
      title: title,
      start: startDate,
      end: endDateInclusive,
      application: application
    }
    events.push(cloneAndMaintEvent)
  }

  for (let value of Object.values(allEnvironmentsCalendar)) {
    // console.log(value)

    const startDate = new Date(value['Start Date'])
    const endDate = new Date(value['End Date'])
    const endDateInclusive = new Date(endDate.setDate(endDate.getDate() + 1))
    let title = value['Description']
    let application = value['Application']
    if (!value['End Date']) {
      value['End Date'] = value['Start Date']
    }

    var allEnvCalendarEvent = {
      title: title,
      start: startDate,
      end: endDateInclusive,
      application: application
    }
    events.push(allEnvCalendarEvent)
  }

  return loadingCloneAndMaint && loadingAllEnvironmentsCalendar ?
  <div></div> : (
    <div className="AllEnvironmentsCalendar">
      <Calendar
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        eventPropGetter={(events) => {
          const backgroundColor = 
            events.application === "PROD" ? 'rgb(0, 83, 4)'
          : events.application === "STAGE" ? 'rgb(0, 156, 8)'
          : events.application === "UAT" ? 'rgb(0, 1, 80)'
          : events.application === "DEV" ? 'rgb(0, 110, 255)'
          : 'black'
          return {style: {backgroundColor}}
        }}
        views={views}
        style={{ height: "800px" }}
        />
    </div>
  )
}
export default function AllEnvironmentsCalendar({container}) {

  const hasAdmin = useHasAdmin()
  const location = useLocation()
  return (
    <>
      <div className="nav-bumper" id="all-environments" />
      <div className="section-wrapper">
        <h1>&nbsp;All Environments Calendar</h1>
        {location.pathname ==="/" && hasAdmin &&
    <ModalForCosmosUpdate data={container}/>
    }
        {location.pathname !=="/" && hasAdmin &&
    <ModalForCosmosUpdate data={container}/>
    }
        {location.pathname ==="/" && hasAdmin &&
    <ModalToReplaceItemInCosmosDB data={container} section="All Environments Calendar"/>
    }
        {location.pathname !=="/" && hasAdmin && 
    <ModalToReplaceItemInCosmosDB data={container} section="All Environments Calendar"/>
    }
        <AllEnvironmentsCalendarDisplay container={container} />
      </div>
    </>
  );
}


