import { useMsal } from '@azure/msal-react';
import { post } from '../../lib/api';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/esm/Button';


export default function BoundaryAppsForm(data) {

  const { instance, accounts } = useMsal()
  // console.log(data["data"])
  
  //Headers isolation
  const loadingBoundaryApps = data["data"]['Boundary Apps']["loading"]
  const boundaryAppsHeaders = loadingBoundaryApps ? [] : Object.keys(data["data"]['Boundary Apps']["value"][0])
  const cosmosIdIndex = boundaryAppsHeaders.lastIndexOf("id")
  const boundaryAppsWithoutCosmosMetaTags = boundaryAppsHeaders.slice(0,cosmosIdIndex);

  const onSubmit = async e => {
    e.preventDefault()
    const body = 
    {
      "Application": e.target.Application.value,
      "Purpose": e.target.Purpose.value,
      "Instance Name": e.target["Instance Name"].value,
      "Oracle Environment": e.target["Oracle Environment"].value,
      "Sizing": e.target.Sizing.value,
      "Data": e.target.Data.value,
    }
    console.log(JSON.stringify(body))
    
    const tokenRequest = {account : accounts[0]}
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
    const response = post("boundaryapps", tokenResponse.idToken, JSON.stringify(body))
    console.log(response)
    alert("Boundary Application Added")
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        {boundaryAppsWithoutCosmosMetaTags && boundaryAppsWithoutCosmosMetaTags.map((header, headerIndex) => (
          <FloatingLabel label={`${header}`} key={`${headerIndex}`} id={header}>
            <Form.Control
              placeholder={header}
              name={header}
              required
              >
            </Form.Control>
          </FloatingLabel> ))}
          <br></br>
          <Button variant="primary" type="submit">
            Confirm
          </Button>
      </Form>
    </>
  )
}