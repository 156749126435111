import Modal from "react-bootstrap/Modal"
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import SectionWithTable from "./Table/SectionWithTable";


export default function ModalToReplaceItemInCosmosDB ({data, section}) {


  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const container = data["All Environments Calendar"]

  return (
    <>
      <Button variant="primary" style={{marginLeft:"5px", marginBottom:"5px"}} onClick={handleShow}>
        Edit
      </Button>
      <Modal
      backdrop="static"
      keyboard="false"
      show={show}
      onHide={() => {
        handleClose();
      }}
      dialogClassName="modal-width-update"
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update {section} Table</Modal.Title>
        </Modal.Header>
        <SectionWithTable container={data[section]} data={container} section={section} addButton="false"/>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            handleClose();
          }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}